import "./search.scss";
import * as dompack from 'dompack';
import consiliosearch from "@mod-consilio/js/internal/search.rpc.json";
import services from "../../shared/services.rpc.json";

/*
  cSearchSuggest
    suggest container is inserted in end of search form
  options: catalog: string , consilio catalog name
           rpc: boolean    , switch when option is selected then plain submit or just fire submit event for rpc
*/
class cSearchSuggest
{
  constructor( inputnode )
  {
    this.inputnode = inputnode;

    this.options = JSON.parse(inputnode.getAttribute("data-suggest"));

    if( !this.options.catalog )
    {
      console.warn("No catalog set");
      return;
    }

    this.formnode = dompack.closest(this.inputnode, "form");
    this.attachnode = this.formnode;

    if( inputnode.dataset.suggestparent )
    {
      this.attachnode = dompack.qS(inputnode.dataset.suggestparent);
      if( !this.attachnode )
      {
        console.error("Invalid suggest parent container");
        return;
      }
    }

    this.autoposition = inputnode.dataset.autoposition;

    if( this.autoposition )
    {
      window.addEventListener("resize", ev => {
        if( !this.suggestwrapper )
          return;
        this.positionSuggestWrapper();
      });
    }

    this.history = [];

    document.body.addEventListener("click", ev =>
    {
      if( !this.suggestwrapper )
        return;

      let chknode = dompack.closest(ev.target, "form");
      if( !chknode || chknode != this.formnode )
        this.removeSuggestions();
    });

    this.words = this.inputnode.value;
    this.inputnode.addEventListener("keyup", ev =>
    {
      if( this.suggestwrapper && ev.keyCode == 40 )
      { //down 40, up 38
        ev.preventDefault();
        this.suggestwrapper.querySelector("li").focus();
      }

      if( this.updatetimer )
        clearTimeout(this.updatetimer);

      let inpval = this.inputnode.value;
      if( inpval.trim )
        inpval = inpval.trim();

      if( inpval != this.words )
        this.updatetimer = setTimeout( ev => this.updateList( inpval ), 200);
    });

    this.inputnode.addEventListener("search", ev => this.removeSuggestions() );//case search clear field
  }

  async updateList( words )
  {
    this.words = words;

    let minwordlength = 3;

    //first check if we have already suggestions for given input
    for( let i = this.history.length - 1; i >= 0 && this.words.length >= minwordlength; --i)
    {
      if( this.history[i].words == this.words )
      {
        this.updateSuggestions(this.history[i].values);
        return;
      }
    }

    if( this.words != "" && this.words.length >= minwordlength )
    {
      if(this.suggestionrpc)
        consiliosearch.rpcResolve(this.suggestionrpc, null);

      this.suggestionrpc = consiliosearch.suggest(
        { type: "catalog"
        , catalog: this.options.catalog
        }
        , this.words
        , { doccount: ""
          , count: 10
          });

      let results = await this.suggestionrpc;
      if(results)
        this.updateSuggestions(results.values);
    }
    else if( this.suggestwrapper )
      this.removeSuggestions();
  }

  updateSuggestions( suggestions )
  {
    this.formnode.classList.add("suggestionsactive");

    this.history.push({"words" : this.words, "values" : suggestions });
    if( this.history.length > 100 ) //limit nr items in history
      this.history.shift();

    if( !this.suggestwrapper )
    {
      this.listitems = [];
      this.suggestwrapper = <ul class="wh-autocomplete-values" style="display:none;" />;

      this.attachnode.appendChild(this.suggestwrapper);

      if( this.autoposition )
        this.positionSuggestWrapper();

      this.suggestwrapper.addEventListener("keydown", ev =>
      {
        if( ev.keyCode == 38 )
        { // Up
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = this.listitems.length - 1; i >= 0; --i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if( i > 0 )
                focusednode = this.listitems[i - 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 40 )
        {// Down
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = 0; i < this.listitems.length; ++i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if(i < this.listitems.length - 1)
                focusednode = this.listitems[i + 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 27 ) // Esc
        {
          this.inputnode.focus();
          this.removeSuggestions();
        }
        else if( ev.keyCode == 13 ) // Enter
        {
          let item = dompack.closest( ev.target, "li");
          if( item )
          {
            this.inputnode.value = item.getAttribute("data-value");
            this.removeSuggestions();//remove list

            if( this.options.rpc ) // trigger Rpc
              dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
            else
              this.formnode.submit();//basic submit
          }
        }
      });
    }

    if( !suggestions.length )
      return;//Just keep previous results visible

    this.suggestwrapper.style.display = "";
    dompack.empty(this.suggestwrapper);//first empty container

    for( let item of suggestions )
    {
      let node = <li class="suggestion" tabindex="0" data-value={item.value} />;
      node = highlightMatch(node, item.value, this.words );

      node.addEventListener("click", ev => {
        this.inputnode.value = item.value;
        this.removeSuggestions();//hide/remove list

        if( this.options.rpc ) // trigger Rpc
          dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
        else
          this.formnode.submit();//basic submit
      });

      this.listitems.push(node);

      this.suggestwrapper.appendChild(node);
    }
  }

  positionSuggestWrapper()
  {
    let pos1 = this.attachnode.getBoundingClientRect();
    let pos2 = this.inputnode.getBoundingClientRect();

    //First set position wrapper to zero
    this.suggestwrapper.style.left = "0px";
    this.suggestwrapper.style.right = "0px";
    this.suggestwrapper.style.top = "0px";
    this.suggestwrapper.clientWidth;//force css refresh

    let x1 = pos2.left - pos1.left;
    if( x1 != 0 )
      this.suggestwrapper.style.left = x1 + "px";

    let x2 = pos1.right - pos2.right;
    if( x2 != 0 )
      this.suggestwrapper.style.right = x2 + "px";

    let y1 = pos2.bottom - pos1.top;
    if( y1 != 0 )
      this.suggestwrapper.style.top = y1 + "px";
  }

  removeSuggestions()
  {
    this.formnode.classList.remove("suggestionsactive");

    if( !this.suggestwrapper )
      return;
    this.suggestwrapper.parentNode.removeChild( this.suggestwrapper );
    this.suggestwrapper = null;
  }
}

function highlightMatch(el, value, words)
{
  words = words.toUpperCase();
  let i = value.toUpperCase().indexOf(words);
  let l = words.length;
  let previ = 0;
  while( i > -1 )
  {
    if( i > previ )
      el.appendChild( document.createTextNode(value.substring(previ,i)) );

    el.appendChild( <span class="match">{value.substr(i, words.length)}</span> );//

    previ = i + l;
    i = value.toUpperCase().indexOf(words, i + l);
  }
  if( value.length > previ )
    el.appendChild( document.createTextNode(value.substr(previ)) );

  return el;
}

dompack.register("input[data-suggest]", node => new cSearchSuggest( node ) );

class searchForm
{
  constructor(node)
  {
    this.node = node;
    this.container = node.parentNode;

    this.inpnode = dompack.qS( this.node, "input[name='words']");
    this.togglenode = dompack.qS(".header__search__toggle");

    this.node.addEventListener("submit", ev => this.onSubmit(ev));

    //if input gets focus, make sure it is visible
    this.inpnode.addEventListener("focus", ev => this.show() );
    this.mainnode = dompack.qS("main");

    let words = this.getUrlParam("words");
    if( words )
    {
      this.show();
      this.inpnode.value = words;
      this.doSearch(words);
    }

    this.togglenode.addEventListener("click", ev => {
      if( this.container.classList.contains("active") )
        this.hide();
      else
        this.show();
    });

    document.body.addEventListener("keydown", ev => {
      if( ev.keyCode == 27 /* Esc */)
        this.hide();//close it
      else if(ev.keyCode == 13 /* Enter */ && ev.target == this.togglenode)
      {
        if( this.container.classList.contains("active") )
          this.hide();
        else
          this.show();
      }
    });
  }

  onSubmit(ev)
  {
    ev.preventDefault();

    let words = this.inpnode.value.trim();
    this.replaceURLParameter("words", words);
    if( words && words != this.words )
      this.doSearch(words);
  }

  async doSearch(words)
  {
    this.words = words;

    document.documentElement.classList.add("showsearchresult");

    if( !this.resultsoverlay )
    {
      this.resultsnode = <div class="searchresults centercontent" />;
      this.resultsoverlay = <div class="searchresults-overlay">{this.resultsnode}</div>;

      //animate: Hide main-element and show resultspanel 
      this.mainnode.style.overflow = "hidden";
      this.mainnode.style.transition = "max-height 300ms";

      this.resultsoverlay.style.maxHeight = "0";
      this.mainnode.style.maxHeight = this.mainnode.clientHeight + "px";

      dompack.after(this.mainnode, this.resultsoverlay);

      this.mainnode.clientHeight;//force css update

      this.mainnode.style.maxHeight = "0";
      this.resultsoverlay.style.maxHeight = "100vh";
      this.resultsoverlay.style.flex = "1 1 auto";

      setTimeout(() => {this.resultsoverlay.style.maxHeight = "";}, 300);
    }
    else
      dompack.empty(this.resultsnode);

    this.resultsnode.classList.add("searchresults--loading");

    if( this.rpc ) //stop already running rpc
      services.rpcResolve(this.rpc, null);

    this.rpc = services.Search(this.words);
    let result = await this.rpc;
    this.showResults(result);
  }

  showResults(res)
  {
    if( !this.resultsoverlay )
      return;//probably searchform closed while retrieving results

    dompack.empty(this.resultsnode);

    let rescontent = <div class="searchresults__header">
                       <b>{res.totalcount + (res.totalcount == 1 ? ' item' : ' items')}</b> found for <span class="searchresults__words">{'"'+res.words+'"'}</span>
                     </div>;
    this.resultsnode.appendChild(rescontent);

    this.resultsnode.appendChild(<ul class="searchresults__list">
                                { res.results.map( item => this.renderItem(item) )}
                                 </ul> );
    if( res.next > 0 )
    {
      this.nextnode = <div class="searchresults__more" data-next={res.next} role="button">Load more</div>;
      this.nextnode.addEventListener("click", ev => this.loadMore() );
      this.resultsnode.appendChild(this.nextnode);
    }

    this.resultsnode.classList.remove("searchresults--loading");
  }

  renderItem(item)
  {
    return <li>
              <a href={item.link} class="searchresults__list__item">
                <span class="title">{item.title}</span>
                {item.dateformatted ? <span class="date">{item.dateformatted}</span> : null}
                {item.summary ? <span class="description">{item.summary}</span> : null}
                <span class="url">{item.link}</span>
              </a>
            </li>;
  }

  async loadMore()
  {
    if( this.resultsnode.classList.contains("searchresults--loading") )
      return;

    this.resultsnode.classList.add("searchresults--loading");
   

    this.rpc = services.Search(this.words, {page : parseInt(this.nextnode.dataset.next)});
    let result = await this.rpc;
 
    let listnode = dompack.qS(this.resultsnode,".searchresults__list");
    result.results.forEach(item => {
      listnode.appendChild(this.renderItem(item));
    });

    if( !result.next )
      dompack.remove(this.nextnode);

    this.resultsnode.classList.remove("searchresults--loading");
  }

  show()
  {
    this.inpnode.focus();
    this.container.classList.add("active");
  }

  hide()
  {
    if( this.rpc ) //stop already running rpc
      services.rpcResolve(this.rpc, null);

    if( this.resultsoverlay )
      dompack.remove(this.resultsoverlay);
    this.resultsoverlay = null;

    this.inpnode.blur();
    this.inpnode.value = "";
    this.container.classList.remove("active");
    this.words = "";
    this.mainnode.style = "";
    this.mainnode = null;

    document.documentElement.classList.remove("showsearchresult");
  }

  replaceURLParameter(parameter, value)
  {
    if( !history.replaceState )
      return;

    let urlparamstr = location.search.replace(/\+/g,"%20");

    let urlparams = urlparamstr ? urlparamstr.substring(1).split("&") : [];
    let replaced = false;
    for( let i = urlparams.length - 1; i >= 0; --i )
    {
      if( urlparams[i].indexOf( parameter + "=") == 0 )
      {
        replaced = true;
        if( value == "" )
          urlparams.splice(i,1);
        else
          urlparams[i] = parameter + "=" + encodeURIComponent(value);
      }
      else if( !urlparams[i] )
        urlparams.splice(i,1);//Just some cleanup
    }

    if( !replaced )
      urlparams.push(parameter + "=" + encodeURIComponent(value));

    let baseurl = document.location.href.split(/[?#]+/)[0];
    history.replaceState(null, "", baseurl + (urlparams.length ? "?" + urlparams.join("&") : "") );
  }

  getUrlParam(name)
  {
    let val = new URL(location.href).searchParams.get(name);
    return val ? decodeURIComponent(val) : "";
  }
}

dompack.register(".header__search__form", node => new searchForm(node) );
