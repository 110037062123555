import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import * as preload from 'dompack/extra/preload';
import "@mod-publisher/js/analytics/ga4";
import '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

import './shared/forms/forms';
import './shared/rtd/rtd';
import './gemmsc.scss';
import './shared/shared.scss';
import './header';
import './footer';
import './pages';
import './widgets';

/*
  Load images for elements where data-loadimage is set when visible in viewport
  img element will be appended inside this element
  and adds scrollfx when elements with scrollfx class gets into view
*/
class scrollListener
{
  constructor()
  {
    this.refresh();

    window.addEventListener("scroll", ev => this.checkVisibleElements(ev), { passive: true } );
    window.addEventListener("gemmsc:refreshcontent", ev => this.checkVisibleElements(ev) );

    window.addEventListener("resize", ev => this.checkVisibleElements(ev) );
    window.addEventListener("load", ev => this.checkVisibleElements(ev) );
  }

  refresh()
  {
    this.imagecontainers = [];
    for( let node of document.querySelectorAll("[data-loadimage]") )
      this.imagecontainers.push(node);

    this.scrollfxnodes = [];
    for( let node of document.querySelectorAll(".scrollfx:not(.scrollfx--done)") )
      this.scrollfxnodes.push(node);

    this.checkVisibleElements({type:"refresh"});
  }

  checkVisibleElements(ev)
  {
    if( !this.imagecontainers.length && !this.scrollfxnodes.length )
      return;//Nothing todo

    if( !this.viewport || (ev && ev.type === "resize") )
    {
      this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                      , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                      };
    }

    for( let i = this.scrollfxnodes.length - 1; i >= 0; --i )
    {
      let pos = this.scrollfxnodes[i].getBoundingClientRect();
      if( !pos.width )
        continue;//Probably hidden element

      if( ev && ev.type === "refresh" && pos.bottom < 0 )
        this.scrollfxnodes[i].classList.add("scrollfx--fromtop");

      if( pos.bottom > 0 && pos.top < this.viewport.y )
      {
        this.scrollfxnodes[i].classList.add("scrollfx--done");
        this.scrollfxnodes.splice(i,1);
      }
    }

    for( let i = this.imagecontainers.length - 1; i >= 0; --i )
    {
      let pos = this.imagecontainers[i].getBoundingClientRect();
      if( !pos.width )
        continue;//Probably hidden element

      if( pos.bottom > 0 && pos.top < this.viewport.y )
      {
        let srcset = this.imagecontainers[i].dataset.loadimage;
        this.preloadImage( this.imagecontainers[i], srcset );
        //remove attribute so image is not loaded multipletimes incase of refresh
        this.imagecontainers[i].removeAttribute("data-loadimage");
        this.imagecontainers[i].classList.add("image-loaded");
        this.imagecontainers.splice(i,1);
      }
    }
  }

  async preloadImage( wrappernode, srcset )
  {
    let preloadedimage = await preload.promiseImage( srcset.split(" ")[0] );
    if( preloadedimage )
    {
      let title = wrappernode.getAttribute("title");
      preloadedimage.node.setAttribute("alt", title ? title : "");
      preloadedimage.node.setAttribute("srcset", srcset);
      preloadedimage.node.style.opacity = 0;
      preloadedimage.node.style.transition = "opacity 300ms";
      wrappernode.appendChild( preloadedimage.node );
      void(wrappernode.clientWidth);// force css update
      preloadedimage.node.style.opacity = 1;
    }
  }
}

dompack.onDomReady(() =>
{
  openLinksInNewWindow({ extensions : ["pdf"] });

  new scrollListener();
});
