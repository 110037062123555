import "./footer.scss";
import * as dompack from 'dompack';
import shareCurrentPage from '../shared/sharepage';

dompack.register(".footer__share", node => {
  node.addEventListener("click", ev => onFooterShare(node,ev));
  node.addEventListener("keyup", ev => onFooterShare(node,ev));
});

function onFooterShare(node,ev)
{
  if( ev.type == "keyup" )
  {
    if( ev.keyCode == 27 /* Esc */ )
    {
      node.classList.remove("active");
      if( document.activeElement && document.activeElement.closest(".footer__share") )
        document.activeElement.blur();
      return;
    }
    else if( ev.keyCode != 13 ) /* ignore if not 'Enter' key */
      return;
  }

  if( ev.target.closest(".footer__share__toggle") )
  {
    node.classList.toggle("active");
    if( !node.classList.contains("active") && document.activeElement && document.activeElement.closest(".footer__share") )
      document.activeElement.blur();
  }
  else
  {
    let sharenode = ev.target.closest(".footer__share__networks > li[data-network]");
    if( sharenode )
      shareCurrentPage(sharenode);
  }
}
