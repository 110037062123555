import "./images.scss";
import * as dompack from 'dompack';
import * as swipe from 'dompack/browserfix/swipelistener';

class browseImages
{
  constructor(node)
  {
    this.node = node;
    this.items = dompack.qSA(this.node, ".widget-images__slide");
    this.idx = 0;

    this.node.addEventListener("click", ev => this.onClick(ev) );
    this.node.addEventListener("keyup", ev => this.onClick(ev) );

    swipe.enable(node);
    node.addEventListener("dompack:swipe", ev => {
      if( ev.detail.direction == "e" )
        this.gotoSlide(this.idx - 1);
      else if( ev.detail.direction == "w" )
        this.gotoSlide(this.idx + 1);
    });
  }

  next()
  {
    this.gotoSlide(this.idx + 1);
  }

  previous()
  {
    this.gotoSlide(this.idx - 1);
  }

  gotoSlide(idx)
  {
    if( idx >= this.items.length )
      idx = 0;
    else if( idx < 0 )
      idx = this.items.length - 1;

    if( idx != this.idx )
    {
      this.items[idx].parentNode.style.transform = "translateX(" + -100*idx + "%)";
      this.idx = idx;
    }
  }

  onClick(ev)
  {
    if( ev.type == 'keyup' )
    {
      if( ev.keyCode == 37 /* ArrowLeft */ )
        this.previous();
      else if( ev.keyCode == 39 /* ArrowRight */ )
        this.next();
      return;
    }

    if( ev.target.closest(".widget-images__nav__next") )
      this.next();
    else if( ev.target.closest(".widget-images__nav__previous") )
      this.previous();
  }
}

dompack.register(".widget-images--multiple", node => new browseImages(node) );
