import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import { DateField, TimeField } from '@mod-publisher/js/forms/fields/datetime';
import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
import './forms.scss';

import './buttons.css';
import './datetime.scss';
import './dialog.scss';
import './fileedit.scss';
import './pulldown.scss';
import './radio-checkbox.scss';

//Enable forms and our builtin validation
forms.setup({validate:true});

//Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

//Replaces date/time fields
dompack.register(".wh-form__date", node => new DateField(node));
dompack.register(".wh-form__time", node => new TimeField(node));

//enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
googleRecaptcha.setupGoogleRecaptcha();
dialogapi.setupDialogs(options => dialog.createDialog('gemmsc-dialog', options));//needed for recaptcha

//Open links in option labels in new window
dompack.register(".wh-form .wh-form__optionlabel a", node => {
  node.setAttribute("target","_blank");
});

dompack.onDomReady(() =>
{
  if( document.documentElement.classList.contains("webform--striped") )
  {
    //Add wrappers around form groups
    dompack.qSA(".wh-form__fieldgroup--group").forEach((node,i) => {
      let groupnode = node.nextSibling;
      let container = <div class="groupcontainer" />;
      node.parentNode.insertBefore(container,node);
      container.appendChild(node);
      while( groupnode && groupnode.classList.contains("wh-form__fieldgroup") && !groupnode.classList.contains("wh-form__fieldgroup--group") )
      {
        let nextnode = groupnode.nextSibling;
        container.appendChild(groupnode);
        groupnode = nextnode;
      }
    });
  }
});

//Styled (native) pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = dompack.closest(selectnode, ".wh-form__pulldown-styled");
  if( !wrappernode )
  { //if not already has styling wrapper, add..
    wrappernode = <div class="wh-form__pulldown-styled" />;
    selectnode.parentNode.insertBefore(wrappernode, selectnode);
    wrappernode.appendChild(selectnode);
    wrappernode.appendChild(<span class="arrow GEM-icon-03-chevron-down-16px" />);
  }

  if (MutationObserver)
  {
    if( selectnode.disabled )
      wrappernode.classList.add("wh-form__pulldown-styled--disabled");
    new MutationObserver(function(){
      dompack.toggleClass(wrappernode, "wh-form__pulldown-styled--disabled", selectnode.disabled);
    }).observe(selectnode, {subtree: false, attributes: true, characterData: false});
  }

});
