import "./foldablecontent.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";

// Note: custom foldin/out animation not possible with details element
//  if you want animated foldin/out, details/summary elements should be replace with div's

export class foldableContent
{
  constructor(node, options )
  {
    this.node = node;
    this.duration = options && options.duration ? options.duration : 200;//Animation duration in ms

    this.containernode = this.node.querySelector(".wh-details__container");
    this.containernode.style.transition = "height " + this.duration + "ms";

    this.summarynode = this.node.querySelector(".wh-details__summary");
    this.summarynode.addEventListener("click", ev => this.toggleAnimatedDetails() );
  }

  toggleAnimatedDetails()
  {
    let open = !this.node.classList.contains("wh-details--open");

    clearTimeout(this._timer);
    this.containernode.style.height = this.containernode.clientHeight + "px";
    this.containernode.clientHeight;//force css update

    this.containernode.style.height = ( open ? this.containernode.children[0].clientHeight : 0 ) + "px";

    dompack.toggleClass(this.node, "wh-details--open", open );

    this._timer = setTimeout( () => {
      this.containernode.style.height = "";
      if( open )
        dompack.dispatchCustomEvent(this.node, "gemmsc:refreshcontent", { bubbles: false, cancelable: true});
    }, this.duration );
  }
}

function moveChildnodes(target, childnode )
{
  while( childnode )
  {
    let nextnode = childnode.nextSibling;
    target.appendChild(childnode);
    childnode = nextnode;
  }
}

function replaceElement(newel, oldel)
{
  oldel.parentNode.insertBefore(newel, oldel);
  oldel.parentNode.removeChild(oldel);

  return newel;
}

export function replaceDetailsSummary( detailsnode )
{
  if( detailsnode.nodeName != "DETAILS" )
    return detailsnode;

  let summarynode = detailsnode.querySelector("summary");
  if( !summarynode )
    return detailsnode;

  //Add container around items after summary element
  let containernode = <div class="wh-details__container" />;
  let innernode = <div class="wh-details__container__inner" />; //used for measuring height
  containernode.appendChild(innernode);
  moveChildnodes(innernode, summarynode.nextSibling);

  //Replace details with div
  let el = <div />;
  for(let i = detailsnode.attributes.length - 1; i >= 0; i--)
    el.setAttribute(detailsnode.attributes[i].name, detailsnode.attributes[i].value);
  el.classList.add("wh-details");
  if( detailsnode.getAttribute("open") != null )
  {
    el.removeAttribute("open");
    el.classList.add("wh-details--open");
  }

  moveChildnodes(el, detailsnode.firstChild);
  detailsnode = replaceElement(el, detailsnode);

  //Replace summary with div
  el = <div />;
  for(let i = summarynode.attributes.length - 1; i >= 0; i--)
    el.setAttribute(summarynode.attributes[i].name, summarynode.attributes[i].value);
  el.setAttribute("tabIndex","0");
  el.classList.add("wh-details__summary");
  moveChildnodes(el, summarynode.firstChild);
  summarynode = replaceElement(el, summarynode);

  //Finaly add container with the foldable content
  detailsnode.appendChild(containernode);

  return detailsnode;
}

export function setupBasicDetailsSummary(detailsnode)
{ //Basic details/summary elements without nice animations
  //Workaround click event if browser doesn't support html5 details/summary
  let summarynode = detailsnode.querySelector("summary");
  if( summarynode && typeof HTMLDetailsElement === "undefined" )
  {
    summarynode.addEventListener("click", ev => {
      if( detailsnode.hasAttribute("open") )
        detailsnode.removeAttribute("open");
      else
        detailsnode.setAttribute("open","");
    });
  }
}


function findDetailsHash()
{
  let hash = location.hash.substr(1);
  if( hash )
  {
    let target = document.getElementById(hash);
    if( target )
    {
      let detailsnode = dompack.closest(target.parentNode,"details,.wh-details");
      while( detailsnode )
      { // check of if is subset inside tree. Also open parents
        if( detailsnode.nodeName == "DETAILS" )
          detailsnode.setAttribute("open","");
        detailsnode.classList.add("wh-details--open");
        detailsnode = dompack.closest(detailsnode.parentNode,"details,.wh-details");
      }
    }
  }
}

export function setDetailsEvents()
{
  if( location.hash.length > 1 )
    findDetailsHash();

  if( dompack.qS("details") || dompack.qS(".wh-details") )
  {
    window.addEventListener("hashchange", ev => findDetailsHash());

    //Accessibility for foldablecontent
    window.addEventListener("keydown", ev => {
      if( ev.keyCode == 13 )
      {
        let node = dompack.closest(ev.target,".wh-details__summary");
        if( node )
          domevents.fireHTMLEvent(node, 'click');
      }
    });
  }
}

/*
dompack.register("details", node => {
  node = replaceDetailsSummary(node);
  new foldableContent(node);
});
*/

dompack.onDomReady(setDetailsEvents);
