
export default function shareCurrentPage( networkelement )
{
  let networktype = networkelement.dataset.network;
  let link = location.href;

  switch (networktype)
  {
    case "facebook":
    { // Unfortunately Facebook removed support to pass an title, image, etc through the share page (you need to use wh.social.facebook for that)
      // https://developers.facebook.com/x/bugs/357750474364812/
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(link)
                 , "wh_fbshare"
                 , "width=845,height=347,menubar=no,location=no,status=no");
    } break;

    case "whatsapp":
    {
      let shareurl = "whatsapp://send?text=";

      let title = getPageTitle(networkelement);
      if (title != "")
        shareurl += encodeURIComponent(title) + ":%20";

      window.open( shareurl + encodeURIComponent(link)
                 , "wh_whatsappshare"
                 , "width=845,height=347,menubar=no,location=no,status=no");
    } break;

    case "twitter":
    { // https://dev.twitter.com/web/tweet-button/web-intent
      let shareurl = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(link);

      let title = getPageTitle(networkelement);
      if (title != "")
        shareurl += "&text=" + encodeURIComponent(title);

      window.open(shareurl
                 , "wh_twittershare"
                 , "width=550,height=300,menubar=no,location=no,status=no");
    } break;

    case "linkedin":
    { // https://developer.linkedin.com/docs/share-on-linkedin
      let shareurl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(link);

      let title = getPageTitle(networkelement);
      if (title != "")
        shareurl += "&title=" + encodeURIComponent(title);

      window.open(shareurl
                 , "wh_linkedinshare"
                 , "width=600,height=700,menubar=no,location=no,status=no");
    } break;

    case "pinterest":
    { // https://developers.pinterest.com/pin_it/
      let media = networkelement.dataset.media;
      if (!media)
      {
        media = document.head.getElements('meta[property="og:image"]').pick();
        if (media)
          media = media.getAttribute("content");
      }
      if (media)
      {
        let shareurl = "https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(link) + "&media=" + encodeURIComponent(media);

        let title = getPageTitle(networkelement);
        if (title != "")
          shareurl += "&description=" + encodeURIComponent(title);

          window.open(shareurl
                    , "wh_pinterestshare"
                    , "width=750,height=550,menubar=no,location=no,status=no");
      }
    } break;

    case "email":
    { // https://en.wikipedia.org/wiki/Mailto
      // http://www.ietf.org/rfc/rfc6068.txt
      let shareurl = "mailto:?body=" + encodeURIComponent(link);

      let title = getPageTitle(networkelement);
      if (title != "")
        shareurl += "&subject=" + encodeURIComponent(title);

      document.location.href = shareurl;
    } break;

    case "print":
    {
      window.print();
    } break;

    default:
    {
      console.error("No such network '" + networktype + "'");
    }
  }
}

function getPageTitle(networkelement)
{ // find the page title
  let title = "";

  // try data-title attribute (use hasAttribute, so it can be left empty to force no title)
  if (networkelement.hasAttribute("data-title"))
    title = networkelement.getAttribute("data-title");
  else
  { // try <og:title>
    let ogtitle = document.querySelector('meta[property="og:title"]');
    if (ogtitle)
    {
      title = ogtitle.getAttribute("content");
    }
    else
    {
      // try <title>
      let titleattr = document.querySelector("title");
      if (titleattr)
        title = titleattr.textContent;
    }
  }
  return title;
}
