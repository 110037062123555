import "./header.scss";
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import * as cookie from "dompack/extra/cookie";

let _logoimgs = ["logo-img1.svg","logo-img2.svg","logo-img3.svg","logo-img4.svg"];

/* pick random image part for logo, prevent same image loading twice */
dompack.register(".header__logo__img", node => {
  let previmg = cookie.read("gemmsclogo");
  if( !previmg )
    previmg = "";

  let rndlst = _logoimgs.filter(src => previmg != src );
  let idx = ~~(Math.random() * rndlst.length);
  node.src = whintegration.config.imgroot + rndlst[idx];
  cookie.write("gemmsclogo", rndlst[idx]);
});

class headerSlideShow
{
  constructor(node)
  {
    this.duration = 10000;//in ms

    this.slides = dompack.qSA(node, ".header__images__slide");
    this.jmpbtns = dompack.qSA(node, ".header__images__jumpbtns > span");
    this.idx = 0;

    node.addEventListener("click", ev => this.onClick(ev) );

    this.timer = setTimeout(() => this.next(), this.duration);
  }

  onClick(ev)
  {
    let btn = ev.target.closest(".header__images__jumpbtns > span");
    if( !btn )
      return;

    let idx = this.jmpbtns.findIndex( _ => _ == btn );
    if( idx > -1 && idx != this.idx )
      this.gotoSlide(idx);
  }

  next()
  {
    this.gotoSlide(this.idx + 1);
  }

  gotoSlide(idx)
  {
    clearTimeout(this.timer);

    if( idx >= this.slides.length )
      idx = 0;
    else if( idx < 0 )
      idx = this.slides.length - 1;

    this.idx = idx;

    this.slides.forEach((node,i) => {
      dompack.toggleClass(node,"active", i == this.idx);
      dompack.toggleClass(this.jmpbtns[i],"active", i == this.idx);
    });

    this.timer = setTimeout(() => this.next(), this.duration);
  }
}

class headerLayout
{
  constructor(node)
  {
    this.node = node;
    window.addEventListener("load", ev => this.checkPosition(ev) );
    window.addEventListener("resize", ev => this.checkPosition(ev) );
    document.fonts.ready.then(() => this.checkPosition({type:'load'}) );
    this.checkPosition();
  }

  checkPosition()
  {
    document.documentElement.classList.remove("mobilelayout");//first reset

    let winx = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let pos = this.node.getBoundingClientRect().right;

    if( pos > winx ) //if search element doesn't fit within window, then switch to mobile navigation
      document.documentElement.classList.add("mobilelayout");
  }
}

function toggleMobileNav(ev)
{
  if( ev.type == "keyup")
  {
    if( ev.keyCode == 13 /* enter */)
      document.documentElement.classList.add("showmobilenav");
    else if(ev.keyCode == 27 /* Esc */ )
      document.documentElement.classList.remove("showmobilenav");
    else
      return;
  }
  else
    document.documentElement.classList.toggle("showmobilenav");

  //reset all active subitems
  dompack.qSA(".header__navbar__navcontainer .showsubitems").forEach(node => node.classList.remove("showsubitems"));

  if( document.documentElement.classList.contains("showmobilenav") )
  { //Open mobile menu at current active page
    let activetree = dompack.qSA(".header__navbar__navcontainer .active");
    activetree.pop();//Ignore last item
    activetree.forEach( (node,i) => {
      node.closest("li").classList.add("showsubitems");
      node.closest(".navblock").classList.add("showsubitems");
    });
  }
}

function toggleMainNavSubitems(ev)
{
  let togglenode = ev.target.closest(".subitems__toggle,.mobileback");
  if( !togglenode )
    return;

  if( togglenode.classList.contains("mobileback") )
  {
    togglenode.closest(".navblock.showsubitems").classList.remove("showsubitems");
    setTimeout( () => {
      togglenode.closest("li.showsubitems").classList.remove("showsubitems");
    },300);
  }
  else
  {
    togglenode.closest("li").classList.toggle("showsubitems");
    togglenode.closest(".navblock").classList.toggle("showsubitems");
  }
}

//subitems__toggle
dompack.register(".header__navbar .mainnav", node => {
  node.addEventListener("click", ev => toggleMainNavSubitems(ev) );
});

dompack.register(".header__navbar__mobiletoggle", node => {
  node.addEventListener("click", ev => toggleMobileNav(ev) );
  node.addEventListener("keyup", ev => toggleMobileNav(ev) );
});

dompack.register("header .header__search", node => new headerLayout(node) );
dompack.register(".header__images--slideshow", node => new headerSlideShow(node));
